
.t-staff{
    
    .splash{   
        max-width: 1200px;
        padding:0 20px;
        margin:0 auto;
        margin-bottom: 4rem;
        position: relative;
        display:grid;
        grid-template-areas: 
            'title image'
            'content image'
        ;
        grid-template-columns: minmax(auto, 400px) 1fr;
        grid-template-rows: auto 1fr;
        grid-gap:1rem;
        .contact-btn{
            display: inline-block;
            justify-self: start;
            margin-top: 4rem;
            @media screen and (max-width:576px){
                margin-left: auto;
                margin-right: auto;
                display: block;
                width: fit-content;
            }
        }
        .page-graphic{
            grid-area: image;
            img{
                width: 100%;
                display: block;
                margin-left: auto;
            }
        }
        .page-body{
            grid-area: content;  
            font-size: 18px;
        }
        @media screen and (max-width:768px){
            grid-template-areas: 
            'image'
            'title'
            'content'
        ;
        grid-template-columns: 1fr;
        grid-template-rows: auto auto auto;
        }
    }
    .content-title{
        font-size: 18px;
        margin-bottom: clamp(20px, 2vw, 6rem);
    }
    .content-body{
        padding:clamp(20px, 2vw, 6rem) 0;
    }
    .pager{
        display: block;
        margin-top: 2rem;
        .button{
            background-color: $grey;
            transition: transform .2s ease-in-out;
            margin:0 .5rem;
            &:hover{
                color:white;
                transform: scale(1.1);
            }
        }
    }
    .three-col{
        grid-gap: 2rem;
    }
    .staff-card{
        position: relative;
        aspect-ratio: 1/1;
        padding-top: 100%; //fallback for aspect-ratio
        &.with-bg{
            background-color: #2A2A2A;
        }
        .card-img{
            img{
                position: absolute;
                width:100%;
                height: 100%!important;
                object-fit: cover;
                inset:0;
            }
        }
        .card-default-img{
            position: absolute;
            width:100%;
            height: 100%!important;
            object-fit: contain;
            padding: 1rem;
            inset:0;
        }
        .card-content{
            position: absolute;
            bottom:0;
            width: 100%;
            display: flex;
            justify-content: space-between;
            background-color: rgba(16, 24, 32, .3);
            padding: .5rem 1.5rem;
            
            .card-title{
                margin:0;
                font-size: 18px;
                font-weight: 500;
            }
            .card-summary{
                font-size: 12px;
                margin:0;
                margin-top: 1rem;
            }
            
            .staff-links{
                display: flex;
                align-items: center;
                a{
                    margin-left: .5rem;
                    &:first-child{
                        margin-left:0; 
                    }
                    img{
                        width:14px;
                        height: 14px!important;
                        object-fit: contain;
                        &:hover{
                         
                            filter:brightness(0) saturate(100%) invert(81%) sepia(96%) saturate(1617%) hue-rotate(324deg) brightness(104%) contrast(86%);
                            
                        }
                    }
                }
            }
            @media screen and (max-width:300px) {
                text-align: center;
                justify-content: center;
                flex-wrap:wrap;
                .card-summary {
                    margin: 5px 0;
                }
            }
        }
    }
}