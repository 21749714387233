@import "var";

/*============ header ============*/
header.top {
    font-family: "Segoe UI", "Segoe", sans-serif;
    font-size:14px;

}
.header-bar-top {
    color:#ffffff;
    position: relative;
    padding:20px;
    padding-bottom: 0;
    .mobile-menu{
        @media screen and (max-width:576px) {
            top: 20px;
        }
    }
}


header.top li {
    vertical-align:top;
}
.header-menu {
    position: relative;
    padding: 2rem;
    display: grid;
    grid-template-columns: 1fr fit-content(100%) 1fr;
    align-items: end;
    
    a.logo{
   
        grid-column: 2;
    }
    li a{
        background-color: $yellow;
        padding:10px 20px;
        color: $black;
        transition: transform .2s linear;
        &:hover{
            font-weight: 700;
            transform: scale(1.1);
        }
    }
    @media screen and (max-width:768px) {
        padding:2rem 1rem;
    }
    @media screen and (max-width:576px) {
        .mobile-menu{
            position: fixed;
            top:20px;
            left:20px;
        }
    }
}
.header-menu > ul {
    text-align:right;
    display: flex;
    justify-content: flex-end;
    align-items: flex-end;
    flex-wrap: wrap;
    & > li{
        margin-left: 1rem;
        margin-top: 1rem;
    }
    @media screen and (max-width:576px) {
        display: none;
    }
    // margin-left:auto;
}

.header-bar-bottom {
    background:#333333;
    color:#fff;
    border-top:1px solid #222222;
    position:relative;
}
.main-menu ul {
    text-align:justify;
    line-height:0;
}
.main-menu ul::after {
    content:"";
    display:inline-block;
    width:100%;
    line-height:0px;
    vertical-align:top;
}
.main-menu li {
    margin:5px;
    line-height:1;
}
.main-menu li.depth-0 {
    vertical-align:middle;
}

.main-menu a {
    padding:15px 0;
}
.main-menu li > ul {
    position:absolute;
    top: 100%;
    left:0;
    right:0;
    background:#f6f6f6;
    color:#082133;
    z-index:10000;
    text-align: left;
    display:none;
}
.main-menu li:hover > ul {
    display:block;
}
.main-menu li > ul::before,
.main-menu li > ul::after {
    content:"";
    background:#f6f6f6;
    position:absolute;
    top:0;
    bottom:0;
    width:0;
    height:100%;
}
.main-menu li > ul::before {
    left:0;
    padding-left:50vw;
    margin-left:-50vw;
    background:#f6f6f6;
}
.main-menu li > ul::after {
    right:0;
    padding-right:50vw;
    margin-right:-50vw;
    background:#f6f6f6;
}
.main-menu .depth-1 .title {
    font-size:18px;
}
.main-menu .depth-1 a {
    width: 160px;
    margin: 30px;
}