/** menu generic **/
nav ul {
    list-style:none;
    padding: 0;
    margin: 0;
}
nav li {
    display: inline-block;
    box-sizing: border-box;
}
nav a {
    display: block;
    color:inherit;
    text-decoration: none;
}