/*============ sub page============*/
.t-page {

    .splash{   
        max-width: 1200px;
        padding:0 20px;
        margin:0 auto;
        margin-bottom: 4rem;
        position: relative;
        display:grid;
        grid-template-areas: 
            'title image'
            'content image'
        ;
        grid-template-columns: minmax(auto, 400px) 1fr;
        grid-template-rows: auto 1fr;
        grid-gap:1rem;
        .page-graphic{
            grid-area: image;
            display: flex;
            img{
                width: 100%;
                object-fit: contain;
                margin: auto;
            }
        }
        .page-body{
            grid-area: content;  
            font-size: 18px;
        }
        @media screen and (max-width:768px){
                grid-template-areas: 
                'image'
                'title'
                'content'
            ;
            grid-template-columns: 1fr;
            grid-template-rows: auto auto auto;
        }
    }
	.page-title {
        font-size: clamp(18px, 5vw, 64px);
        padding:0;
        margin-bottom: 0;
        font-weight: 700;
	}
}
