@import "var";

.mobile-menu {



    width: 34px;
    height: 30px;
    margin: 2px;
 
    cursor: pointer;
    z-index:100;
}
.mobile-menu > span {
    display: block;
    width: 100%;
    height: 2px;
    border-radius: 2px;
    background-color: #fff;
    margin: 6px 0;
    transition: all 0.5s ease;
}
.slide-menu-active header.top .mobile-menu{
    opacity: 0;
}

/***** Slide menu ****/

.slide-menu {
    display: flex;
    flex-direction: column;
	background: #2B2B2B;
    width: 100%;
    max-width:300px;
    z-index:1000;
	position: fixed;
	height: 100%;
	top: 0;
	left: -300px;
    padding-left:3rem;
    overflow-y: auto;
    .mobile-menu{
        position: relative;
        margin-top:2rem;
        margin-left: 0;
        margin-bottom: 2rem;
        left: 0;
        span.one {
            -webkit-transform: rotate(-45deg) translate(-6px, 6px);
            transform: rotate(-45deg) translate(-6px, 6px);
        }
        span.three {
             -webkit-transform: rotate(45deg) translate(-6px, -6px) ;
            transform: rotate(45deg) translate(-6px, -6px) ;
        }
        span.two {
            opacity: 0;
        }
    }
    .logo-small{
        width: 24px;
        margin-top: 2rem;
        margin-bottom: 4rem;
    }
    .menu-contact{
        margin-top: 3rem;
        a.row, .row{
            display: flex;
            gap:1rem;
        }
        span.label{
            // margin-right: 1rem;
            img{
                width: 18px;
                height: 18px!important;
                object-fit: contain;
            }
        }
        .detail{
            margin-right: 3px;
        }
        .site-links{
            display: flex;
            flex-direction: column;
            margin-top: 1.5rem;
            gap:1.5rem;
            .site-link{
                display: flex;
                gap:1rem;
                width:auto;
                height: auto;
                padding:0;
                margin:0;
                img{
                    width: 18px;
                    height: 18px;
                    aspect-ratio: 1/1;
                }
            }
        }
    }
    @media screen and (max-width:576px) {
        max-width: none;
        text-align: center;
        padding-right:3rem;
        .row{
            justify-content: center;
        }
        left: -100%;
        .logo-small{
            margin-bottom: 2rem;
        }

        .site-links{
            align-items: center;
        }
    }
    
}
.slide-menu-active .slide-menu {
    left: 0px;
}
.slide-menu-active {
    // .mobile-menu {
    //     display: fixed;
    // }
}
.site-content-menu-slide {
    overflow-x:hidden;
    position:relative;
    left:0;
}
/* transition for menu slide */
.slide-menu,
.site-content-menu-slide,
.mobile-menu {
	-webkit-transition: all 0.3s ease;
	-moz-transition: all 0.3s ease;
	transition: all 0.3s ease;
}
.slide-menu .menu-title {
	padding: 1em;
	color:#fff;
	background:#999;
	margin:0;
	font-weight:bold;
}
.slide-menu ul {
    padding:0;
    margin:0;
    list-style:none;
}
.slide-menu ul ul {
	background:#999;
}
.slide-menu ul ul ul{
	background:#666;
}
.slide-menu li {
    position:relative;
    display: block;
}
.slide-menu li > ul {
    display:none;
}
.slide-menu li.expand-active > ul {
    display:block;
}
.slide-menu a {
	display: inline-block;
	color: #fff;
	font-size: 16px;
	font-weight: 300;
	border-bottom: 1px solid transparent;

    padding-bottom: 5px;
    margin-bottom: 1.5rem;
	text-decoration:none;
    transition: transform .2s linear, font-weight .05s linear;
    &:hover {
        color: $yellow;
        // border-bottom: 1px solid $yellow;
        font-weight: 700;
        transform: scale(1.05);
        img{
            filter: brightness(0) saturate(100%) invert(81%) sepia(96%) saturate(1617%) hue-rotate(324deg) brightness(104%) contrast(86%);
        }
    }
}
.slide-menu li.has-sub {
    position: relative;
    display: block;
}
.slide-menu li.has-sub > a {
    position: relative;
    border-right: 1px solid #030b16;
    margin-right: 50px; /* space for drop */
}
.slide-menu 
.slide-menu li.has-sub > a:after {
    position: absolute;
    content: "";
    display: block;
    left: 100%;
    bottom: -1px;
    background-color: #030b16;
    width: 50px;
    height: 1px; /* border bottom */
    z-index: 100;
}
.slide-menu ul .expand {
    position: absolute;
    top: 0;
    right: 0;
    border-left: 1px solid #030b16;
    height: 54px;
    width: 50px;
    cursor: pointer;
}
.slide-menu ul .expand i {
    content:"";
    display: block;
    height: 100%;
    width: 100%;
    background: url('../assets/menu-drop.svg') no-repeat center center;
    background-size: 30%;
    -webkit-transition: -webkit-transform 500ms ease;
    transition: -webkit-transform 500ms ease;
    transition: transform 500ms ease;
    transition: transform 500ms ease, -webkit-transform 500ms ease;
}
.expand-active > .expand i {
    -webkit-transform: rotate(180deg);
    -moz-transform: rotate(180deg);
    -ms-transform: rotate(180deg);
    -o-transform: rotate(180deg);
    transform: rotate(180deg);
}

.slide-menu-active .site-content-menu-slide {
    left: 240px;
}
 

