.t-contact{
    .header-bar-top{
        position: absolute;
        top:0;
        left:0;
        width: 100%;
        z-index: 100;
    }
    .splash{   
        height: 30vw;
        min-height: 300px;
        position: relative;
        display:flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        background-color: rgba(0,0,0,.5);
        img{
            inset:0;
            position: absolute;
        
            height: 100% !important;
            width: 100% !important;
            object-fit: cover;
            z-index: -1;
        }
        .page-title{
            font-size: clamp(18px, 5vw, 64px);
            text-align: center;
            padding:.5rem 1rem;
            font-weight: 700;
        }
    }
    .section-title{
        font-size:clamp(16px, 4vw, 20px);
        font-weight: 500;
        color: $yellow;
    }
    .section-body{
        font-size:clamp(18px, 5vw, 30px);
        font-weight: 200;
    }
    .site-links{
        .site-link{
            height: 30px;
            width: auto;
            .social-link-icon{
                height: 100%!important;
                width: auto;
                object-fit: contain;
                object-position: left;
                margin-right: .5rem;
            }
        }
    }
    .col-space-between{
        display: flex;
        flex-direction: column;
        justify-content: space-between;
    }
    .js-float-wrap{
        label{
            color: #000;
        }
    }
    
    main{
        a{
            text-decoration: none;
            color:inherit;
        }
        textarea{
            padding-top:10px;
            min-height: 165px;
        }
        input, textarea{
            color:$black;
            font-size: clamp(16px, 3vw, 20px);
        }
        .contact-title{
            justify-content: space-between;
            color: $yellow;
            margin-bottom: 2rem;
            border-top: 1px solid $yellow;
            padding-top: 2rem;
        }
        button[type=submit]{
            float: left;
            color:$black;
            background-color: $yellow;
            padding: .75rem 2rem;
        }
        .recaptcha-notice{
            margin-top: 1rem;
            float: right;
            clear: both;
        }
        .result-wrapper{
            height: 100%;
            display: flex;
            flex-direction: column;
            justify-content: center;
            text-align: center;
            font-size: clamp(16px, 3vw, 20px);
          
            #form-result{
                margin-top: 0;
                font-size: clamp(20px, 5vw, 47px);
                
            }
        }
        @media screen and (max-width:768px) {
            text-align: center;
            .section-body{
                justify-content: center;
            }
            .contact-wrapper{
                padding:3rem 0;
            }
            .site-link:last-child{
                margin-right: 0;
            }
        }
    }
        
    /*============ Contact ============*/
    .contact-details.has-map,
    .map-wrap {
        display: inline-block;
        vertical-align: bottom;
        width: 48%;
        box-sizing: border-box;
    }
    .contact-details.has-map {
        padding-right: 10px;
    }

    .contact-details p {
        margin: 5px 0;
    }
    .contact-wrapper{

        padding:clamp(20px, 2vw, 6rem) 0;
        form p {
            margin: 10px 0;
        }
    }
  
    .map-wrap {
        position: relative;
        background-color: #d3d3d3;
        padding-top: 70%;
        width: 100%;
    }
    .kms-loader {
        position: absolute;
        top: 50%;
        left: 50%;
        margin-top: -50px;
        margin-left: -50px;
    }
    #map,
    #map-click {
        display: none;
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
    }
    #map {
        display: block;
    }
}