@import "var";

/*============ footer ============*/
footer{
    border-top:1px solid $grey;
    background-color: #221F20;
    h3.label{
        font-size: 18px;
        font-weight: 500;
        margin:0;
        margin-bottom: 2rem;
        margin-top:1rem;
    }
 
    .footer-wrapper{
        padding:4rem 20px;

        max-width: 1200px;
        margin:0 auto;
        display:grid;
        grid-template-areas: 
        'content menu links contact';
        grid-template-columns: 2fr 1fr 1fr 1fr;
        grid-gap:2rem;
        font-size: 16px;
       
        .footer-content{
            grid-area: content;
            font-size: 14px;
            a.logo{
                margin-bottom: 2rem;
            }
            & > div{
                max-width: 390px;
            }
        }
        .footer-menu{
            grid-area: menu;
            ul{
                list-style: none;
                padding:0;
                li{
                    margin-bottom: 2rem;
                    a:hover{
                        color: $yellow;
                    }
                }
            }
        }
        .footer-site-links{
            grid-area: links;
            .site-links{
                display:flex;
                flex-direction: column;
                .site-link {
                    display:flex;
                    height: auto;
                    width: auto;
                    margin-top: 0;
                    padding:0;
                    margin-bottom: 2rem;
                    .social-link-icon{
                        height: 20px!important;
                        width: 20px;
                        object-fit: contain;
                        margin-right: 1rem;
                    }
                }
            }
        }
        .footer-contact{
            grid-area: contact;
            h4.label{
                opacity: 0;
                pointer-events: none;
            }
            p.row{
                margin-top: 0;
                margin-bottom: 2rem;
            }
            span.label{
                margin-right:1rem;
            }
            span.detail{
                margin-right:5px;
            }
            
        }

        @media screen and (max-width:998px) {
            grid-template-areas: 
            '. content .'
            'menu links contact';
            grid-template-columns: 1fr 1fr 1fr;
            .footer-content{
                grid-column: 1 / -1;
                text-align: center;
                & > div {
                    max-width: none;
                }
            }
        }
        @media screen and (max-width:576px) {
            grid-template-areas: 
            'content'
            'menu'
            'links'
            'contact';
            grid-template-columns: 1fr;
            text-align: center;
            justify-items: center;
            .footer-contact{
                h4.label{
                    display: none;
                }
            }
        }
    }
 
}

/** bykeo ***/
.bykeo {
    display: block;
    text-align: center;
    font-size: 12px;
    padding-top: 20px;
    padding-bottom: 20px;
    width: 100%;
    box-sizing: border-box;
    background-color: $yellow;
}
.bykeo a {
    color: #000;
    text-decoration:none;
}
.bykeo img {
    vertical-align: middle;
    display: inline-block;
    transition: transform 500ms ease;
    filter: grayscale(1)brightness(0)invert(0);
}
.bykeo a:hover img{
    transform-origin: center;
    transform: translateX(-200%) rotate(-360deg);

}