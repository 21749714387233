/*============ splash ============*/
/*  banner */
.t-home{
    .header-bar-top{
        position: absolute;
        top:0;
        left:0;
        width: 100%;
        z-index: 100;
    }
    .splash{
        position: relative;
        display:flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        background-color: rgba(0,0,0,.5);
        min-height: 100vh;
        @media screen and (max-width:960px) {
            min-height: auto;
            aspect-ratio: 16/9;
        }
        .banners{
            height: 100%;
            position: absolute;
            left:0;
            right:0;
            top:0;
            bottom:0;
            z-index: -1;
            .banner-content{
                display: none;    
            }
            .banner-navigation{
                display: none;
            }
        }
        
        .banner-stage{
            height: 100%;
            a{
                display: block;
                position: absolute;
                width: 100%;
                height: 100%;
                background-size: cover;
                background-repeat: no-repeat;
                opacity: 0;
                transition: opacity 2s ease;
                &.active{
                    position: relative;
                    opacity: 1;
                }
            }
            
        }
        img{
  
            height: 100% !important;
            width: 100% !important;
            object-fit: cover;
            z-index: -1;
        }
        .page-title{
            font-size: clamp(18px, 5vw, 64px);
            text-align: center;
            padding:.5rem 1rem;
            font-weight: 700;
            @media screen and (max-width:960px) {
                position:absolute;
                bottom:20%;
                left:0;
                right:0;
                margin:0;
                padding:0;
            }
        }
        &::after{
            position: absolute;
            content:"";
            bottom:0;
            background-color: $yellow;
            margin:0 auto;
            height: 40%;
            width: 2px;
            @media screen and (max-width:960px) {
                height: 18%;
            }
        }
    }
    .subtemplate-contact{
        background-color: $black;
    }
    
    .subtemplate-contact::after{
        position: absolute;
        content:"";
        top:-6rem;
        background-color: $yellow;
        margin:0 auto;
        height: 8rem;
        width: 2px;
    }
}