@import "var";

.subtemplate-service, .subtemplate-project, .subtemplate-banner-carousel, .subtemplate-contact{
    background-color: $black;
    text-align: center;
    padding:4rem 0;
    .block-body {
        font-size:18px;
        font-weight: 500;
    }
    .block-content {
        margin: clamp(20px, 2vw, 6rem) 0;
    }
    .card.with-bg{
        background-color: #2A2A2A;
    }
    .card-default-img{
        object-fit: contain;
        padding: 1rem;
    }
    @media screen and (max-width:500px) {
        padding:2rem 0;  
        .block-content {
            margin: 3rem 0;
        }
    }
    @media screen and (max-width:300px) {
        padding:1rem 0;  
     
    }
}
.subtemplate-service{
    background-color: $black;
    .card {
        aspect-ratio: auto;
        padding-top:0;
        width:100%;
        img, .card-default-img {
            position:static;
            aspect-ratio: 1/1;
            width: 100%;
            max-width: 120px;
            height: auto !important;
            margin: 30px;
            @media screen and (max-width:768px) {
                max-width: 100px;
                margin: 50px;
            }
        }
        .card-content {
            min-height: 0;
        }
    }
    .card-title{
        position:static;
        z-index: 10;
        font-weight: 700;
        font-size: 18px;
        margin-bottom: 5px;
    }
}
.subtemplate-contact{
    background-color: $black;
    position: relative;
}

.subtemplate-testimonial{
    background-color: $black;

    padding:clamp(20px, 3vw, 4rem) 0;
    .block-wrapper{
        display:grid;
        grid-template-columns:auto 3fr;
        grid-gap:1rem 3rem;
        .block-content{
            display: grid;
            grid-template-columns: 1fr auto;
            grid-template-areas: 
                ". quote" 
                "testimonial .";
            grid-gap:.5rem 0;
            .logo-small{
                grid-area: quote;
                width: 62px;
                transform: rotate(180deg);
            }
            .testimonial-content{
                grid-area: testimonial;
                font-size: clamp(16px, 3vw, 20px);
                font-weight: 500;
                overflow: hidden;
            }
            @media screen and (max-width:500px) {
                .logo-small{
                    display: none;
                }
            }
          
        }
        .block-title-wrapper{
            display: grid;
            grid-template-columns: auto auto;
            grid-template-areas: 
                ". title" 
                "logo .";
            grid-gap: 0 .5rem;
            .logo-small{
                grid-area: logo;
                // transform: rotate(253deg);
                align-self: flex-start;
                max-width: 88px;
                width: 88px;
            }
            .block-title{
                grid-area: title;
                align-self: flex-end;
                font-size:clamp(18px, 4vw, 30px);
                font-weight: 700;
                max-width: 280px;
                margin:0;
            }
        }
        .testimonial-name-wrapper{
            display: grid;
            grid-template-columns: auto 1fr;
            grid-gap: 1rem;
            position: relative;
            grid-template-areas: 
            "dash name" 
            ". org";
            align-items: center;
            .dash{
                grid-area: dash;
                display: inline-block;
                width: 67px;
                height: 2px;
                background-color: $white;
            }
            .testimonial-name{
                margin: 0;
                position: relative;
                grid-area: name;
            }
            .testimonial-org{
                margin: 0;
                grid-area: org;
            }
            
        }
        @media screen and (max-width:992px) {
            grid-template-columns: 1fr;

            .block-title-wrapper{
                justify-content: center;
                grid-row: 1;
            }

        }
    }

}

.subtemplate-banner-carousel {
    .block-content{
        padding-top: 2rem;
    }
	li.splide__slide {
		margin: 0px 30px !important;
        display: flex;
        align-items: center;
        a{
            display: flex;
            align-items: center;
        }
	}
}

.subtemplate-banner-tab{
    margin-bottom:4rem;
    position: relative;
    overflow-y: visible;
    .box-container{
        position: absolute;
        inset:0;
        display: grid;
        grid-template-columns: .75fr 1.25fr;
        grid-gap: 2rem;
        pointer-events: none;
        .box{
            inset: 0;
            border: 11px solid $yellow;
            transform: translatex(-20px);
     
        }
    }
    h2 {
        margin-bottom: 3rem;
    }
    .banners{
        display:grid;
        grid-template-columns: .75fr 1.25fr;
        grid-template-areas: 'menu content';
        
        .banner-navigation-body{
            position: relative;
          
            grid-area: menu;
            display: flex;
            flex-direction: column;
            margin-bottom: 1rem;
            flex-wrap: wrap;
            .banner-nav-item{
                margin-right: 2rem;
                padding-bottom: 2rem;
                margin-bottom: 1rem;
                cursor: pointer;
                .banner-title, .banner-caption{
                    display: none;
                }
                .banner-name{
                    font-size: clamp(16px, 4vw, 20px);
                    font-weight:300;
                    &:hover{
                        font-weight: 500;
                    }
                }
                &.active{
                    .banner-name{
                        display:block;
                        font-weight: 500;
                        text-decoration: underline;
                    }
                    
                }
            }
            @media screen and (max-width:576px) {
           
                .banner-nav-item{
                    margin-right: 0;
                    margin-bottom: 0;
                    padding-bottom: 1rem;
                    &.active{
                        border-bottom: none;
                    }

                }
            }
        }
        .banner-stage{
            position: relative;
            grid-area: content;
            .banner{
                opacity: 0;
                position: absolute;
                transition: opacity .3s ease-in-out;
                text-decoration: none;
               
                inset:0;
                &.active{
                    opacity: 1;
                    position: relative;
                }
                .banner-content{
                    display: block;
             
                    .banner-name,.banner-title, .banner-inner-image{
                        display: none;
                    }
                    .banner-caption{
                        font-size: 16px;
                    }
                }
              
                
            }
        }
 
    }
    @media screen and (max-width:576px) {
        .banners{
            grid-template-columns:  1fr;
            grid-template-areas: 
            'menu'
            'content'; 
            .banner-navigation-body{
                flex-direction: row;
                border: 11px solid $yellow;
                transform: translatex(-20px);
                border-left:none;
                padding-top:1rem;
                padding-left:20px;
                .banner-nav-item{
                    margin-right: 10px;
                }
            }
        }
        .box-container{
            display: none;
        }
    }
}

.subtemplate-project{
    .card{
        .card-summary{
            text-align: center;
            font-weight: 400;
        }
    }
}