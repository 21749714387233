@import "var";

/** site links **/
.site-link {
    display: inline-block;
    width: 30px;
    height: 30px;
    margin: 5px;

    &:hover{
        color: $yellow;
        img{
            filter:brightness(0) saturate(100%) invert(81%) sepia(96%) saturate(1617%) hue-rotate(324deg) brightness(104%) contrast(86%);
        }
    }
}