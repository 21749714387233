
.t-projects, .t-products{
    .card .card-content .card-summary{
        text-align: center;
        font-weight: 400;
    }
    .splash{   
        max-width: 1200px;
        padding:0 20px;
        margin:0 auto;
        margin-bottom: 4rem;
        position: relative;
        display:grid;
        grid-template-areas:
            'title image'
            'content image'
        ;
        grid-template-columns: minmax(auto, 400px) 1fr;
        grid-template-rows: auto 1fr;
        grid-gap:1rem;
        .page-graphic{
            grid-area: image;
            img{
                width: 100%;
                
            }
        }
        .page-body{
            grid-area: content;  
            font-size: 18px;
        }
        @media screen and (max-width:768px){
            grid-template-areas: 
            'image'
            'title'
            'content'
        ;
        grid-template-columns: 1fr;
        grid-template-rows: auto auto auto;
        }
    }
    .content-title{
        font-size: 18px;
        margin-bottom: clamp(20px, 2vw, 6rem);
        // font-weight:500;
    }
    .pager{
        display: flex;
        justify-content: center;
        gap:1rem;
        margin-top: 2rem;
        .button{
            background-color: $grey;
            transition: transform .2s ease-in-out;
            border:none;
            &:hover{
                color:white;
                transform: scale(1.1);
            }
            &.active{
                background-color: $yellow;
                color:black;
                pointer-events: none;
            }
         
        }
    }
}

.t-project-item{
    .back-btn{
        margin-top:2rem;
        display: inline-block;
        text-align: center;
        text-transform: uppercase;
    }
    .header-bar-top{
        position: absolute;
        top:0;
        left:0;
        width: 100%;
        z-index: 100;
    }
    .splash{   
        min-height: 100vh;
        position: relative;
        display:flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        background-color: rgba(0,0,0,.5);
        padding: 20px;
        img{
            inset:0;
            position: absolute;
        
            height: 100% !important;
            width: 100% !important;
            object-fit: cover;
            z-index: -1;
        }
        .page-title{
            font-size: clamp(18px, 5vw, 64px);
            text-align: center;
            padding:.5rem 1rem;
            font-weight: 700;
            max-width: 1000px;
            margin-top:100px;
        }
        .page-body{
            text-align: center;
            font-size: clamp(18px, 4vw, 30px);
            font-weight: 700;
            max-width: 1000px;
        }
    }
    .page-content{
        padding:clamp(20px, 2vw, 6rem);
    }
    .kmsgallery-thumbnail-image{
        width: 100%;
        height: 0!important;
        object-fit: cover;
        aspect-ratio: 1/1;
        padding-top: 100% !important;
        margin:0;
    }
    .gallery{
        margin-bottom: 6rem;
    }
    .related-projects{
        &:before{
            content:"";
            top:0;
            left: 20px;
            right: 20px;
            display: block;
            position: absolute;
            border-top:1px solid $yellow;
        }
        padding-top:4rem;
        padding-bottom:4rem;
        .view-all-projects{
            display: inline-block;
            margin-bottom: 2rem;
        }
    }
}