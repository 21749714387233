.row, .flex{
    display: flex;
}
.flex-wrap{
    flex-wrap: wrap;
}
.col{
    display: flex;
    flex-direction: column;
}
.justify-center{
    justify-content: center;
}
.align-items-center{
    align-items: center;
}
.four-col{
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    grid-gap:1rem;
    @media screen and (max-width:992px) {
        grid-template-columns: repeat(3, 1fr);
    }
    @media screen and (max-width:768px) {
        grid-template-columns: repeat(2, 1fr);
    }
    @media screen and (max-width:576px) {
        grid-template-columns:  1fr;
    }
}
.three-col{
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-gap:1rem;
    @media screen and (max-width:992px) {
        grid-template-columns: repeat(2, 1fr);
    }
    @media screen and (max-width:576px) {
        grid-template-columns:  1fr;
    }
}
.two-col{
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-gap:1rem;
    @media screen and (max-width:768px) {
        grid-template-columns:  1fr;
    }
}
.card{
    aspect-ratio: 1/1;
    padding-top: 100%;
    position: relative;
    text-decoration: none;
    color: $white;
    overflow: hidden;
    &.with-bg{
        background-color: #2A2A2A;
    }
    .card-default-img{
        object-fit: contain;
        padding: 1rem;
    }
    img{
        position: absolute;
        inset:0;
        object-fit: cover;
        z-index: 0;
        height: 100%!important;
        width: 100%;
        transition: filter .3s ease-in-out, transform .3s ease-in-out;
    }
   
    .card-content{
        transition: opacity .3s ease-in-out;
        opacity: 0;
        position: absolute;
        inset:0;
        background-color: transparentize($yellow,.3);
        padding:10px;
        z-index: 1;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        min-height: 100%;
        .card-title{
            font-weight: 700;
            font-size: 18px;
            margin-bottom: .5rem;
        }
        .card-summary{
            font-weight: 500;
            margin:0;
            font-size: 16px;
        }
        &::after{
            content:'VIEW';
            margin-top: 2rem;
            border:1px solid $white;
            padding:5px 20px;
            color: $white;
            font-size: 14px;
        }
    }
    &:hover{
        .card-content{
            opacity: 1;
        }
        img{
            filter:brightness(.4);
            transform: scale(1.3);
        }
    }
}
.btn-outline{
    border:1px solid $white;
    padding:15px 25px;
    text-decoration: none;
    color: $white;
    font-size: 16px;
    transition: all .3s ease-in-out;
    &:hover{
        background-color: $yellow;
        border:1px solid $yellow;
        color: $black;
    }
}
.btn-primary{
    background-color: $yellow;
    padding: 10px 20px;
    color: #221F20;
    transition: transform 0.2s linear;
    font-size: 16px;
    &:hover{
        transform: scale(1.2);
    }
    text-decoration: none;
}